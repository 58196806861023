import React from "react";
import "../styles/404.css";

export default function error() {
  return (
    <div className="body">
      <div id="message">
        <h2>404. That's An Error.</h2>
        <h1>Page Not Found :(</h1>
        <p>
          The requested URL was not found on this server. Please check the URL
          for mistakes and try again.
        </p>
      </div>
    </div>
  );
}
